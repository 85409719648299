import axios from "axios";
import { useState, useEffect } from "react";
import { api } from "../utils";
import styles from "../styles/Login.module.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./context/user/UserContext";
import { useContext } from "react";
import Loader from "./Loader";

const Login = () => {
  const { setUserData } = useContext(UserContext);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    if (userData) {
      setUserData(userData);
      if (userData.user.isAdmin || userData.user.isManager) {
        window.history.back();
      } else {
        navigate("/");
      }
    }
  }, []);

  const handleClick = async () => {
    setLoading(true);

    try {
      const response = await axios.post(`${api}/users/login`, {
        email,
        password,
      });
      const data = response.data;
      setUserData(data);
      localStorage.setItem("userData", JSON.stringify(data));
      navigate("/");
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.wrapper}>
          <h1>Login</h1>
          <input
            placeholder="email"
            className={styles.input}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            placeholder="password"
            type="password"
            className={styles.input}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={handleClick} className={styles.button}>
            Log In
          </button>
          {error && <span className={styles.error}>Wrong Credentials!</span>}
        </div>
      )}
    </div>
  );
};

export default Login;
