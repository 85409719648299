import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { api } from "../../utils";
import styles from "../../styles/AddSighting.module.css";
import { UserContext } from "../context/user/UserContext";
import "../../styles/Location.css";
import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";

import { Icon } from "leaflet";
const customIcon = new Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
  iconSize: [38, 38], // size of the icon
});

const AddSighting = () => {
  const { userData } = useContext(UserContext);
  const { user, token } = userData;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [isverified, setIsverified] = useState(false);
  const [marker, setMarker] = useState({ lat: "", lng: "" });
  const [submit, setSubmit] = useState("Submit");

  const AddMarkerToClick = () => {
    useMapEvents({
      click(e) {
        const newMarker = e.latlng;
        setMarker(newMarker);
      },
    });

    return (
      <>
        {marker && (
          <Marker position={marker} icon={customIcon}>
            <Popup>{`Latitude: ${marker.lat}, Longitude: ${marker.lng}`}</Popup>
          </Marker>
        )}
      </>
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setSubmit("Submitting...");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = {
        title,
        description,
        category,
        location: {
          latitude: marker.lat,
          longitude: marker.lng,
        },
        user: user._id,
        username: user.name,
        isverified,
      };
      const response = await axios.post(`${api}/posts/createpost`, data, {
        headers: headers,
      });
      setTitle("");
      setDescription("");
      setCategory("");
      setMarker({ lat: "", lng: "" });
      alert("POST Created");
      setSubmit("Submit");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user.isAdmin) {
      setIsverified(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <label className={styles.label}>
        Title:
        <input
          type="text"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          required
          className={styles.input}
        />
      </label>
      <br />
      <label className={styles.label}>
        Description:
        <textarea
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          required
          className={styles.textarea}
        />
      </label>
      <br />
      <label className={styles.label}>
        Category:
        <select
          value={category}
          onChange={(event) => setCategory(event.target.value)}
          required
          className={styles.select}
        >
          <option value="">--Please choose an option--</option>
          <option value="GHOST/HAUNTED">GHOST/HAUNTED</option>
          <option value="BIGFOOT/CRYPTID">BIGFOOT/CRYPTID</option>
          <option value="UFO/ALIEN">UFO/ALIEN</option>
          <option value="HOLLYWOOD">HOLLYWOOD</option>
          <option value="OTHER">OTHER</option>
          <option value="LOCATIONS">LOCATIONS</option>
        </select>
      </label>
      <label className={styles.label}>
        Latitude:
        <input
          type="number"
          value={marker.lat}
          onChange={(event) =>
            setMarker((prevMarker) => ({
              ...prevMarker,
              lat: event.target.value,
            }))
          }
          required
          className={styles.input}
        />
      </label>
      <label className={styles.label}>
        Longitude:
        <input
          type="number"
          value={marker.lng}
          onChange={(event) =>
            setMarker((prevMarker) => ({
              ...prevMarker,
              lng: event.target.value,
            }))
          }
          required
          className={styles.input}
        />
      </label>
      <MapContainer center={[40.712088496466265, -74.00615319746703]} zoom={12}>
        <TileLayer
          attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <AddMarkerToClick />
      </MapContainer>
      <br />

      <br />
      <button type="submit" className={styles.button}>
        {submit}
      </button>
    </form>
  );
};

export default AddSighting;
