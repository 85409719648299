import { useContext, useEffect, useState } from "react";
import styles from "../styles/Navbar.module.css";
import { Link } from "react-router-dom";
import { UserContext } from "./context/user/UserContext";

const Navbar = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [id, setId] = useState(false);
  function showMenu() {
    setId(!id);
  }
  useEffect(() => {}, [userData]);
  const logout = () => {
    window.localStorage.removeItem("userData");
    setUserData(null);
  };

  return (
    <div className={styles.container}>
      <Link
        to="/"
        // passHref
      >
        <img
          src={require("../assets/logo.png")}
          alt=""
          width="60px"
          height="60px"
          style={{ borderRadius: "20%" }}
        />
      </Link>
      <div className={styles.item} id="contactDetails">
        <div className={styles.texts}>
          <div className={styles.text}>Paranormal Hunter</div>
        </div>
      </div>
      <div className={styles.item} id={id ? styles.showMenu : ""}>
        <ul className={styles.list}>
          {userData?.user.isAdmin && (
            <Link to="/admin">
              <li className={styles.listItem}>Dashboard</li>
            </Link>
          )}
          <Link to="/">
            <li className={styles.listItem}>About Us</li>
          </Link>
          <Link to="/terms">
            <li className={styles.listItem}>Terms & Privacy Policy</li>
          </Link>
          {!userData && (
            <Link to="/login">
              <li className={styles.login}>Login</li>
            </Link>
          )}
          {userData && (
            <>
              <button onClick={() => logout()} className={styles.logoutBtn}>
                <li className={styles.logout}>Logout</li>
              </button>
              <Link to="/delete">
                <li className={styles.listItem}>Delete Account</li>
              </Link>
            </>
          )}
        </ul>
      </div>

      <div className={styles.menuBtn}>
        <button onClick={showMenu}>
          <img
            src={require("../assets/menuBtn.png")}
            alt=""
            width="20px"
            height="20px"
          />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
