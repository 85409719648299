import React from "react";
import Styles from "../styles/Posts.module.css";

const Post = ({ post, index, page, handleDelete }) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{post._id}</td>
      <td>{post.title}</td>
      <td>
        <div className={Styles.description}>
          <textarea
            defaultValue={
              post.description.length >= 50
                ? post.description.substring(0, 50) + "..."
                : post.description
            }
          />
        </div>
      </td>
      <td>
        {post.reasons.map((reason, index) => (
          <span index={index}>
            {reason}
            <br />{" "}
          </span>
        ))}
      </td>
      <td>{post.username}</td>
      <td>{post.user}</td>
      <td>
        <button
          className={Styles.delete_button}
          onClick={() => handleDelete(post._id)}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default Post;
