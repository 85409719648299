import React from "react";
import Styles from "../../styles/Admin.module.css";
import { useNavigate } from "react-router-dom";

const Admin = () => {
  const navigate = useNavigate();

  return (
    <div className={Styles.container}>
      <h1 className={Styles.title}>Dashboard</h1>
      <div className={Styles.admin_dashboard}>
        <div className={Styles.admin_dashboard__button}>
          <button
            className={Styles.admin_dashboard__button_users}
            onClick={() => {
              navigate("/users");
            }}
          >
            Users
          </button>
        </div>
        <div className={Styles.admin_dashboard__button}>
          <button
            className={Styles.admin_dashboard__button_reported_users}
            onClick={() => {
              navigate("/reportedusers");
            }}
          >
            Reported Users
          </button>
        </div>
        <div className={Styles.admin_dashboard__button}>
          <button
            className={Styles.admin_dashboard__button_posts}
            onClick={() => {
              navigate("/posts");
            }}
          >
            Posts
          </button>
        </div>
        <div className={Styles.admin_dashboard__button}>
          <button
            className={Styles.admin_dashboard__button_reports}
            onClick={() => {
              navigate("/reports");
            }}
          >
            Reported Posts
          </button>
        </div>
        <div className={Styles.admin_dashboard__button}>
          <button
            className={Styles.admin_dashboard__button_citing}
            onClick={() => {
              navigate("/AddSighting");
            }}
          >
            Add Sighting
          </button>
        </div>
      </div>
    </div>
  );
};

export default Admin;
