import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./slices/UsersSlice";
import postsReducer from "./slices/PostsSlice";

const store = configureStore({
  reducer: {
    users: usersReducer,
    posts: postsReducer,
  },
});

export default store;
