import React, { useContext, useState } from "react";
import styles from "../styles/DeleteAccount.module.css";
import axios from "axios";
import { api } from "../utils";
import { UserContext } from "./context/user/UserContext";
import { Navigate, useNavigate } from "react-router-dom";

const DeleteAccount = () => {
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(UserContext);
  const { token, user } = userData;
  const id = user._id;
  const [showModal, setShowModal] = useState(false);

  const deleteHandler = async () => {
    try {
      console.log(id, "This is the userId");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.delete(`${api}/users/userdelete`, {
        headers: headers,
        data: { userId: id },
      });
      window.localStorage.removeItem("userData");
      setUserData(null);
      console.log(response, "This is the response from backend.");
      navigate("/");
    } catch {
      console.log("Some Error occured");
    }
  };

  return (
    <div className={styles.container}>
      <button className={styles.btn} onClick={() => setShowModal(true)}>
        Delete My account
      </button>
      {showModal && (
        <div className={styles.modal}>
          <p>Are you sure you want to delete your account?</p>
          <button className={styles.yesBtn} onClick={() => deleteHandler()}>
            Yes
          </button>
          <button className={styles.noBtn} onClick={() => setShowModal(false)}>
            No
          </button>
        </div>
      )}
    </div>
  );
};

export default DeleteAccount;
