import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import Styles from "../styles/Posts.module.css";
import { api } from "../utils";
import axios from "axios";
import { UserContext } from "./context/user/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { setPosts, replacePosts } from "../redux/slices/PostsSlice";
import Post from "./Post";
import Loader from "./Loader";

const Posts = () => {
  const { userData } = useContext(UserContext);
  const token = userData.token;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts.posts);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const observer = useRef();
  const [postsEnd, setPostsEnd] = useState(false);

  const lastPostElementRef = useCallback(
    (node) => {
      console.log("Inside lastPostElementRef");
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page < totalPages) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, totalPages]
  );
  const prevSearchRef = useRef(search);
  const getPosts = async (search) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        `${api}/posts/getAllPosts?page=${page}&search=${encodeURIComponent(
          search
        )}`,
        {
          headers: headers,
        }
      );
      console.log("response : ", response);
      const data = await response.data.data;
      setTotalPages(response.data.totalPages);
      if (prevSearchRef.current !== search || page === 1) {
        dispatch(replacePosts(data));
      } else {
        dispatch(setPosts(data));
      }
      prevSearchRef.current = search;
      if (page === response.data.totalPages) {
        console.log("page : ", page);
        console.log("response.data.totalPages", response.data.totalPages);
        setPostsEnd(true);
      }
    } catch {
      console.log("Some error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (postId) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.delete(`${api}/posts/posts/${postId}`, {
        headers: headers,
      });
      getPosts(search);
    } catch {
      console.log("Error in deleting");
    } finally {
      setLoading(false);
    }
  };
  const clear = () => {
    setSearch("");
    getPosts("");
  };
  useEffect(() => {
    dispatch(replacePosts([]));
    setPage(1);
  }, [search]);
  useEffect(() => {
    console.log("posts: ", posts);

    getPosts(search);
  }, [page]);
  return (
    <div className={Styles.container}>
      <div className={Styles.heading}>
        <h2>POSTS</h2>
      </div>
      <div className={Styles.inputContainer}>
        <input
          type="text"
          value={search}
          className={Styles.input}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search && (
          <button className={Styles.clear_button} onClick={clear}>
            X
          </button>
        )}
        <button
          className={Styles.search_button}
          onClick={() => getPosts(search)}
        >
          Search
        </button>
      </div>

      <div className={Styles.posts_list}>
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Post ID</th>
              <th>Title</th>
              <th>Description</th>
              <th>
                Reasons <br /> (Reporting)
              </th>
              <th>Username</th>
              <th>User ID</th>
              <th>Action</th>
            </tr>
          </thead>

          {posts.map((post, index) => {
            if (posts.length === index + 1) {
              return (
                <tbody ref={lastPostElementRef} key={index}>
                  <Post
                    post={post}
                    index={index}
                    page={page}
                    handleDelete={handleDelete}
                  />
                </tbody>
              );
            } else {
              return (
                <tbody key={index}>
                  <Post
                    post={post}
                    index={index}
                    page={page}
                    handleDelete={handleDelete}
                  />
                </tbody>
              );
            }
          })}
        </table>
      </div>
      <div>{postsEnd && !loading && <h3>No More Posts</h3>}</div>
      {loading && <Loader />}
    </div>
  );
};

export default Posts;
