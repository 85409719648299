import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Login from "./components/Login";
import Aboutus from "./components/Aboutus";
import Terms from "./components/Terms";
import Admin from "./components/pages/Admin";
import { UserContext } from "./components/context/user/UserContext";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { UserProvider } from "./components/context/user/UserContext";
import styles from "./styles/globals.css";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Posts from "./components/Posts";
import UsersList from "./components/UsersList";
import Reports from "./components/Reports";
import ReportedUsers from "./components/ReportedUsers";
import DeleteAccount from "./components/DeleteAccount";
import AddSighting from "./components/pages/AddSighting";

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userData } = useContext(UserContext);

  useEffect(() => {
    if (!userData && pathname !== "/terms" && pathname !== "/") {
      navigate("/login");
    }
  }, [userData, navigate]);

  return (
    <>
      <Navbar />

      <Routes>
        <Route path="/" element={<Aboutus />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/login" element={<Login />} />
        {userData &&
          (userData.user.isAdmin ? (
            <>
              <Route path="/admin" element={<Admin />} />
              <Route path="/posts" element={<Posts />} />
              <Route path="/users" element={<UsersList />} />
              <Route path="reportedusers" element={<ReportedUsers />} />
              <Route path="AddSighting" element={<AddSighting />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/delete" element={<DeleteAccount />} />
            </>
          ) : (
            <Route path="/delete" element={<DeleteAccount />} />
          ))}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
