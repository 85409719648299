import React from "react";
import styles from "../styles/Terms.module.css";

const AboutUs = () => {
  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <div className={styles.introText}>
          <h1>Paranormal Hunter</h1>
          <h2>About Paranormal Hunter:</h2>

          <h3>
            Introducing Paranormal Hunter, the app designed to help you explore
            the paranormal world starting with the world around you!
            <br />
          </h3>

          <h3>
            Whether you’re a believer or a skeptic, Paranormal Hunter is the
            perfect tool for discovering locations where various paranormal
            activities have taken place.
            <br />
            <br />
            With Paranormal Hunter, you can easily search for ghost sightings,
            UFO sightings, Bigfoot and other cryptid sightings from all over the
            world!
            <br />
            Paranormal Hunter uses advanced algorithms to compile a database of
            reported sightings, and allows users to filter their search results
            by location and the type of paranormal activity that they are
            interested in.
          </h3>
          <h3>
            Each location found at Paranormal Hunter provides detailed
            information of the activity reported that can include eyewitness
            accounts and historical information. Users can also add their own
            sighting information and share them with the Paranormal Hunter
            community.
          </h3>

          <h3>
            As a precaution, Paranormal Hunter users are limited to adding one
            location and experience per day to avoid spamming and false
            information.
          </h3>
          <h3>
            Whether you’re a seasoned, professional paranormal investigator or
            just curious about the unknown, Paranormal Hunter is the ultimate
            tool for discovering the most haunted and mysterious locations near
            you and around the world! Download the Paranormal Hunter app and
            start exploring the paranormal world… today!
          </h3>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
