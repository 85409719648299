import React from "react";
import Styles from "../styles/UsersList.module.css";

const UserContainer = ({ user, handleDelete, index }) => {
  return (
    <tr key={user._id}>
      <td>{index + 1}</td>
      <td>{user.name}</td>
      <td>{user._id}</td>
      <td>{user.email}</td>
      <td>
        <button
          className={Styles.delete_button}
          onClick={() => handleDelete(user._id)}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default UserContainer;
