import styles from "../styles/Footer.module.css";

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <img
          src="/img/bg.png"
          // objectFit="cover"
          layout="fill"
          alt=""
        />
      </div>
      <div className={styles.item}>
        <div className={styles.card}>
          <h2 className={styles.motto}>
            {/* copyright@Paranormal Hunter @2023 */}
            Copyright © 2023 Paranormal Hunter®. All rights reserved.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Footer;
