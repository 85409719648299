import React, { useContext, useEffect, useState } from "react";
import Styles from "../styles/Reports.module.css";
import { useNavigate } from "react-router-dom";
import { api } from "../utils";
import axios from "axios";
import { UserContext } from "./context/user/UserContext";

const Reports = () => {
  const { token } = useContext(UserContext);
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const getposts = async () => {
    try {
      const response = await axios.get(`${api}/posts/reportedposts`);
      console.log(response);
      const data = await response.data;
      setPosts(data);
    } catch {
      console.log("Some error occurred");
    }
  };
  useEffect(() => {
    getposts();
  }, []);
  const [input, setInput] = useState(null);
  const handleDelete = async (postId) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.delete(`${api}/posts/posts/${postId}`, {
        headers: headers,
      });
      getposts();
    } catch {
      console.log("Error in deleting");
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.input}>
        <input
          type="text"
          className={Styles.input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button
          className={Styles.delete_button}
          onClick={() => handleDelete(input)}
        >
          Delete
        </button>
        <button
          className={Styles.back_button}
          onClick={() => navigate("/admin")}
        >
          {" "}
          Dashboard
        </button>
      </div>
      <div className={Styles.heading}>
        <h2>Reported Posts</h2>
      </div>
      <div className={Styles.posts_list}>
        <table>
          <thead>
            <tr>
              <th>Post ID</th>
              <th>Reasons</th>
              <th>User ID</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {posts?.map((post) => (
              <tr key={post._id}>
                <td>{post._id}</td>

                <td className="reason">
                  {post.reasons.map((reason, index) => (
                    <span key={index} className="reason">
                      {reason} <br />
                    </span>
                  ))}
                </td>
                <td>{post.user._id}</td>
                <td>
                  <button
                    className={Styles.delete_button}
                    onClick={() => handleDelete(post._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Reports;
