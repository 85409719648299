import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Styles from "../styles/UsersList.module.css";
import axios from "axios";
import { UserContext } from "./context/user/UserContext";
import { api } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { setUsers, replaceUsers } from "../redux/slices/UsersSlice";
import UserContainer from "./UserContainer";
import Loader from "./Loader";

const UserList = () => {
  const { userData } = useContext(UserContext);
  const token = userData.token;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const observer = useRef();
  const [totalPages, setTotalPages] = useState(1);
  const [usersEnd, setUsersEnd] = useState(false);

  const lastPostElementRef = useCallback(
    (node) => {
      console.log("Inside lastPostElementRef");
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page < totalPages) {
          setPage((prevPageNumber) => prevPageNumber + 1);
          console.log(setPage, "setPage");
        } else {
          setUsersEnd(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, totalPages]
  );

  const prevSearchRef = useRef(search);
  const getUsers = async (search) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        `${api}/users/users/getallusers?page=${page}&search=${encodeURIComponent(
          search
        )}`,
        {
          headers: headers,
        }
      );
      console.log("response : ", response);
      setTotalPages(response.data.totalPages);
      const data = response.data.data;

      if (prevSearchRef.current !== search || page === 1) {
        dispatch(replaceUsers(data));
      } else {
        dispatch(setUsers(data));
      }
      prevSearchRef.current = search;
      if (page === response.data.totalPages) {
        console.log("page : ", page);
        console.log("response.data.totalPages", response.data.totalPages);
        setUsersEnd(true);
      }
    } catch {
      console.log("Some error occurred");
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async (userId) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.delete(
        `${api}/users/users/deleteuserbyadmin`,
        {
          headers: headers,
          data: { userId: userId },
        }
      );
      getUsers();
    } catch {
      console.log("Some Error occured");
    } finally {
      setLoading(false);
    }
  };
  const clear = () => {
    setSearch("");
    getUsers("");
  };

  useEffect(() => {
    getUsers(search);
  }, [page]);

  useEffect(() => {
    dispatch(replaceUsers([]));
    setPage(1);
  }, [search]);

  return (
    <div className={Styles.container}>
      <div className={Styles.heading}>
        <h2> USERS</h2>
      </div>
      <div className={Styles.inputContainer}>
        <input
          type="text"
          className={Styles.input}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search && (
          <button className={Styles.clear_button} onClick={clear}>
            X
          </button>
        )}
        <button
          className={Styles.search_button}
          onClick={() => getUsers(search)}
        >
          Search
        </button>
      </div>

      <div className={Styles.user_list}>
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Username</th>
              <th>User ID</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          {users?.map((user, index) => {
            if (users.length === index + 1) {
              return (
                <tbody key={index} ref={lastPostElementRef}>
                  <UserContainer
                    user={user}
                    handleDelete={handleDelete}
                    index={index}
                  />
                </tbody>
              );
            } else {
              return (
                <tbody key={index}>
                  <UserContainer
                    user={user}
                    handleDelete={handleDelete}
                    index={index}
                  />
                </tbody>
              );
            }
          })}
        </table>
      </div>
      <div>{usersEnd && !loading && <h3>No More Users</h3>}</div>

      {loading && <Loader />}
    </div>
  );
};

export default UserList;
